'use client';

import styled from '@emotion/styled';
import { Box, Flex, useTokens } from '@mezzoforte/forge';

export const containerWidth = '1800px';

const { breakpoints } = useTokens.huutokaupat;

export const Container = styled(Box)`
  margin-left: auto;
  margin-right: auto;
  max-width: ${props => props.width ?? containerWidth};
  width: 100%;

  @media screen and (max-width: ${breakpoints.md}) {
    padding: 0 1rem;
  }
  @media screen and (min-width: ${breakpoints.md}) and (max-width: ${breakpoints.lg}) {
    padding: 0 1.5rem;
  }
  @media screen and (min-width: ${breakpoints.lg}) {
    padding: 0 2.5rem;
  }
`;

export const HeroContainer = styled(Flex)`
  color: white;
  margin-left: auto;
  margin-right: auto;
  max-width: ${props => props.width ?? containerWidth};
  width: 100%;
  height: 100%;

  @media screen and (min-width: ${breakpoints.md}) and (max-width: ${breakpoints.lg}) {
    padding: 1.5rem 1.5rem 0;
  }
  @media screen and (min-width: ${breakpoints.lg}) {
    padding: 2rem 2.5rem 0;
  }
`;
