'use client';

import { useEffect } from 'react';
import NextLink from 'next/link';
import { Heading, Link } from '@mezzoforte/forge';
import { Container } from 'components/Container/Container';

interface ErrorPageProps {
  error: Error;
  reset: () => void;
}

export default function ErrorPage({ error }: ErrorPageProps) {
  useEffect(() => {
    console.error(error);
  });

  return (
    <>
      <Container mt={6}>
        <Heading variant="h1">Jotain meni pieleen</Heading>
        <p>Sivustolla tapahtui odottamaton virhe.</p>
        <p>
          <Link as={NextLink} href="/">
            Palaa etusivulle
          </Link>
        </p>
      </Container>
    </>
  );
}
